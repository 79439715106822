<template>
  <div class="container">
    <div class="columns is-multiline is-centered">
      <div class="column is-8 is-5-desktop">
        <div class="box p-6 has-background-light has-text-centered">
          <slot name="header"></slot>
          <slot name="body"></slot>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginBox',
}
</script>

<style scoped>

</style>
