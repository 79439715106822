<template>
  <section class="section is-flex is-align-items-center">
    <LoginBox>
      <template #header>
        <h3 class="columns column is-centered is-vcentered is-size-4 has-text-weight-bold logo">
          <img class=""
               src="/img/logo_v3.png"
               alt="MKVK Minell logo"/>
          <span class="ml-2">Minell</span>
        </h3>
      </template>
      <template #body>
        <div>
          <!--          <b-button @click="test">Test</b-button>-->
          <div v-if="false"
               class="mb-3">
            <b-field label="Email">
              <b-input type="email"
                       ref="loginEmail"
                       validation-message="Hibás email cím!"
                       v-model="loginData.email">
              </b-input>
            </b-field>
            <b-field label="Jelszó">
              <b-input type="password"
                       ref="loginPwd"
                       minlength="6"
                       validation-message="Adj meg egy helyes jelzót!"
                       v-model="loginData.pwd"
                       password-reveal>
              </b-input>
            </b-field>
            <b-button @click="emailPwdLogin"
                      type="is-success">
              Bejelentkezés (Email + Jelszó)
            </b-button>
          </div>
          <div class="buttons is-centered">
            <!-- TODO: Bejelentkezés redirect-->
            <b-button @click="redirectToMKVK"
                      type="is-danger">
              Bejelentkezés az MKVK oldaláról
            </b-button>
            <!--            <b-button v-if="NODE_ENV === 'development' && TEST_USER_JWT_BASIC_USER"-->
            <!--                      @click="testLogin(10)"-->
            <!--                      type="is-danger">-->
            <!--              TEST LOGIN LAWYER-->
            <!--            </b-button>-->
            <!--            <b-button v-if="NODE_ENV === 'development' && TEST_USER_JWT_BASIC_USER"-->
            <!--                      @click="testLogin(20)"-->
            <!--                      type="is-danger">-->
            <!--              TEST LOGIN BASIC USER-->
            <!--            </b-button>-->
            <!--            <b-button v-if="NODE_ENV === 'development' && TEST_USER_JWT_GROUP_LEADER"-->
            <!--                      @click="testLogin(30)"-->
            <!--                      type="is-danger">-->
            <!--              TEST LOGIN GROUP LEADER-->
            <!--            </b-button>-->
            <!--            <b-button v-if="NODE_ENV === 'development' && TEST_USER_JWT_ADMIN"-->
            <!--                      @click="testLogin(40)"-->
            <!--                      type="is-danger">-->
            <!--              TEST LOGIN ADMIN-->
            <!--            </b-button>-->
          </div>
        </div>
      </template>
    </LoginBox>
  </section>
</template>

<script>
import LoginBox from '@/components/Login/LoginBox'
import { logIn } from '@/utils/auth'

export default {
  name: 'Login',
  components: { LoginBox },
  data () {
    return {
      NODE_ENV: process.env.NODE_ENV,
      TEST_USER_JWT_BASIC_USER: process.env.VUE_APP_BASIC_USER_JWT,
      TEST_USER_JWT_GROUP_LEADER: process.env.VUE_APP_GROUP_LEADER_JWT,
      TEST_USER_JWT_ADMIN: process.env.VUE_APP_ADMIN_JWT,
      loginData: {
        email: '',
        pwd: '',
      },
    }
  },
  methods: {
    test () {
      this.$store.dispatch('pingLocalServer')
    },
    testLogin (userLevel = 20) {
      if (Number(userLevel) === 10) logIn(this.TEST_USER_JWT_BASIC_USER)
      if (Number(userLevel) === 20) logIn(this.TEST_USER_JWT_BASIC_USER)
      if (Number(userLevel) === 30) logIn(this.TEST_USER_JWT_GROUP_LEADER)
      if (Number(userLevel) === 40) logIn(this.TEST_USER_JWT_ADMIN)
    },
    redirectToMKVK () {
      window.location.href = 'https://mkvk.hu'
    },
    emailPwdLogin () {
      const isEmailValid = this.$refs.loginEmail.checkHtml5Validity()
      const isPwdValid = this.$refs.loginPwd.checkHtml5Validity()
      if (this.loginData.email && this.loginData.pwd && isEmailValid && isPwdValid) {
        this.$store.dispatch('loginWithEmailAndPwd', this.loginData)
      }
    },
  },
  beforeCreate () {
    if (typeof this.$route.query.jwt === 'string') {
      logIn(this.$route.query.jwt)
    }
  },
}
</script>

<style lang="scss"
       scoped>
@import "../assets/css/_custom-variables.scss";

section {
  background: $main-gradient-fallback !important;
  background: $main-gradient !important;
  height: 100vh;
}

.logo {
  color: $green;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}

</style>
